import React from 'react';

import { Box, Text, Heading, Center, Link, Button } from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';

import WithAnimation from '@/components/Common/WithAnimation';
import Image from '@/components/Common/LazyImage';
import useLang from '@hooks/useLang';

import { IG_FILTER_URL } from '@/constants';
import { BUTTON_PROPS } from '@/constants/colors';
import { BG_INSTAGRAM_FILTER } from '@/constants/assets';

import txt from './locales';

function InstagramFilter() {
  const lang = useLang();

  return (
    <Box bgColor="bgPrimary" width="100%">
      <Box textAlign="center" color="mainColorText" width="100%" position="relative">
        <Box padding="42px 32px">
          <WithAnimation>
            <Heading
              fontFamily="body"
              textAlign="center"
              size="lg"
              fontStyle="italic"
              fontWeight="normal"
              marginBottom="16px"
              color="mainColorText"
            >
              Instagram<br />
              Wedding Filter
            </Heading>
            <Text fontSize="sm" color="mainColorText">
              {txt.subTitle[lang]}
            </Text>
          </WithAnimation>
        </Box>
        {/* Section Photo */}
        <Center marginTop="-12px">
          <Image src={BG_INSTAGRAM_FILTER} maxWidth="80%" borderRadius="16px" boxShadow="md" />
        </Center>
        {/* Thanks Section */}
        <Box padding="42px 32px">
          <WithAnimation>
            <Center marginTop="-8px">
              <Link
                href={IG_FILTER_URL}
                fontFamily="body"
                color="white"
                target="_blank"
                fontWeight="normal"
              >
                <Button
                  leftIcon={<FaInstagram />}
                  variant="ghost"
                  fontFamily="body"
                  bgColor="bgAlternative"
                  letterSpacing="1px"
                  textTransform="uppercase"
                  size="sm"
                  {...BUTTON_PROPS}
                >
                  {txt.textButton[lang]}
                </Button>
              </Link>
            </Center>
          </WithAnimation>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(InstagramFilter);